import { useEffect } from 'react';
import { useLocation } from '@remix-run/react';
import { analytics } from '../utils/analytics';

export function Analytics() {
  const location = useLocation();

  useEffect(() => {
    // Track page views on route changes
    analytics.page({
      path: location.pathname,
      url: window.location.href,
    });
  }, [location]);

  return null;
}